import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
// global components
import Layout from "../components/layout"
import Seo from "../components/seo"

import JSONData from "../data/texts.json"

const page = JSONData.pages.projects

const ProjectsPage = (props) => {
  const data = props.data.photos.edges
  const projects = []
  for (let i = 0; i < data.length; i+=2) {
    const key = data[i].node.base.split("_")[0]
    projects.push({
      "icon": data[i],
      "screen": data[i+1],
      "data": page.items[key]
    }) 
  }
  
  return (
    <Layout>
      <Seo title="Projects" />
        <p className="px-6 py-6">
          {page.description}
        </p>
        <ul>
          {projects.map((item) => {
            return (
              <li className="flex mb-8 hover:bg-slate-50 rounded-2xl" key={item.icon.node.id}>
                <a className="flex no-underline text-slate-800" href={item.data.link}>
                  <div class="mr-6">
                    <div className="w-64 h-64">
                      <GatsbyImage
                        fluid={item.screen.node.childImageSharp.fluid}
                        alt={item.screen.node.base.split("_")[0]}
                        image={getImage(item.screen.node)}
                        className="rounded-3xl"
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="w-16 h-16">
                      <GatsbyImage
                        fluid={item.icon.node.childImageSharp.fluid}
                        alt={item.icon.node.base.split("_")[0]}
                        image={getImage(item.icon.node)}
                        className="z-50 rounded-2xl border border-slate-100	drop-shadow-3xl"
                      />
                    </div>
                    <div>
                      <div className="text-3xl">{item.data.title}</div>
                      <div className="text-base text-slate-500 font-light">{item.data.text}</div>
                      <div className="mt-4 text-base text-slate-500 font-light">Technologies used: {item.data.tech}</div>
                    </div>
                  </div>
                </a>
              </li>
            )}
          )}
        </ul>

    </Layout>
  )
}

//https://mediajams.dev/post/using-dynamic-image-in-gatsby-plugin-image
export const pageQuery = graphql`
{
  photos: allFile(
    sort: {fields: base, order: ASC}
    filter: {extension: {regex: "/(png)/"}, absolutePath: {regex: "/(projects)/"}}
  ) {
    edges {
      node {
        id
        base
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, height: 600, formats: AUTO, width: 600)
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
 `;

 /*



 photos: allFile(
          sort: { fields: base, order: ASC }
          filter: { extension: { regex: "/(png)/" } }
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 400
                  formats: AUTO
  								width: 600
                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }

        */

export default ProjectsPage
